export const products = [
  {
    id: 1,
    category: "vidalar",
    products: [
      {
        name: "DIN 933 / ISO 4017 Tam Diş Metrik Civatalar",
        picture:
          "https://cdn.tekzen.com.tr/images/product/hobisan/2464241/matkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg",
      },
      {
        name: "vida2",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida3",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida4",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida5",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida1",
        picture:
          "https://cdn.tekzen.com.tr/images/product/hobisan/2464241/matkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg",
      },
      {
        name: "vida2",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida3",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida4",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida5",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida1",
        picture:
          "https://cdn.tekzen.com.tr/images/product/hobisan/2464241/matkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg",
      },
      {
        name: "vida2",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida3",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida4",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida5",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
      {
        name: "vida6",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.tekzen.com.tr%2Fimages%2Fproduct%2Fhobisan%2F2464241%2Fmatkap-uclu-vida-yhb-3919mm-100ad-15376151_1000x1000.jpg&imgrefurl=https%3A%2F%2Fwww.tekzen.com.tr%2Fhobisan-39-x-19-mm-matkap-uclu-vida-100-adet-15376151-2506940&tbnid=Ti9lNJIeZt_e2M&vet=12ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ..i&docid=OMu54aTu8izW6M&w=1000&h=1000&q=vida&ved=2ahUKEwizk_vztt_wAhWEwYUKHZM6BZgQMygTegUIARDBAQ",
      },
    ],
  },
  {
    id: 2,
    category: "civatalar",
    products: [
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "civata1",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
    ],
  },
  {
    id: 3,
    category: "somunlar",
    products: [
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "somun",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
    ],
  },
  {
    id: 4,
    category: "percinler",
    products: [
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
      {
        name: "percin",
        picture:
          "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
      },
    ],
  },
  // {
  //   id: 5,
  //   category: "is guvenligi malzemeleri",
  //   products: [
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //     {
  //       subcategory: "bas koruyucular",
  //       products: [
  //         {
  //           name: "endustriyel baretler",
  //           picture:
  //             "https://www.google.com/imgres?imgurl=https%3A%2F%2Fst3.myideasoft.com%2Fidea%2Faa%2F75%2Fmyassets%2Fproducts%2F596%2Fcivata-metrik.jpg%3Frevision%3D1543998967&imgrefurl=https%3A%2F%2Fwww.byhirdavat.com%2Furun%2Fmetrik-8-45-mm-celik-civata&tbnid=bhUz29CmPA6wZM&vet=12ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ..i&docid=90z8Oo7dHoeaLM&w=650&h=650&q=civata&ved=2ahUKEwiRrMHct9_wAhWzw7sIHegXC8oQMygCegUIARDJAQ",
  //         },
  //       ],
  //     },
  //   ],
  // },
]
